:root {
  --header-height: 85px;
  --footer-height: 40px;
  --sidebar-width: 250px;
}

#layout {
  display: grid;
  min-height: 100vh;
  overflow: hidden;
  grid-template-rows: 1fr;
  grid-template-columns: var(--sidebar-width) 1fr;
  grid-template-areas:
    "sidebar main"
}

#layout > .sidebar {
  grid-area: sidebar;
  height: 100vh;
  overflow-y: hidden;
  max-width: var(--sidebar-width);
  background-color: white;
  position: fixed;
  z-index: 50;
}


header > .logo-container {
  width: calc(var(--sidebar-width) - 80px);
  display: flex;
}

#layout > footer {
  grid-area: footer;
  @apply bg-slate-300;
}

#layout > main {
  grid-area: main;
  background-color: #f5f5f5;
  width: calc(100vw - var(--sidebar-width));
  overflow: auto;
  position: relative;
}

@media screen and (max-width: 768px) {
  #layout > .sidebar {
    display: none;
  }
  #layout {
    grid-template-areas:
      "header header"
      "main main"
      "footer footer" ;
  }
  #layout > .sidebar-footer {
    display: none;
  }

  #layout > main {
    width: 100vw;
    overflow: scroll;
  }
}