*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}

blockquote {
  display: revert;
  margin-top: revert;
  margin-bottom: revert;
  margin-left: revert;
  margin-right: revert;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Color */

:root {
  --accent: 183 74% 44%;
}
.accent {
  color: hsl(var(--accent));
}
.background-tint {
  background-color: rgba(0,0,0,.4);
  background-blend-mode: multiply;
}
/* Form */

.power-form label {
  font-size: 12px;
}

.power-form-vertical .power-form-item-label, .power-col-24.power-form-item-label, .power-col-xl-24.power-form-item-label{
  padding: 0;
}

.power-form-item-control-input{
  min-height: auto;
}

/* Collapse Custom Start */
.power-collapse {
  background-color: transparent;
}
.power-collapse > .power-collapse-item {
  border-bottom: 1px solid #bbb;
}
/* Collapse Custom End */

/* Table Custom Start */

.power-table-cell {
  font-size: 12px;
}
.table-row-light {
  background-color: #ffffff;
}
.table-row-dark {
  background-color: #fbfbfb;
}
.power-table-thead > tr > th {
  background: #fff;
  font-weight: 600;
  border-bottom: 1px solid #005594;
}
.power-table-thead > tr > th:not(:last-child):not(.power-table-selection-column):not(.power-table-row-expand-icon-cell):not([colspan])::before {
  background-color: #fff;
}
.power-table-tbody > tr > td {
  border-bottom: 0px;
}
.power-table-tbody > tr:last-child > td{
  border-bottom: 1px solid #f0f0f0;
}
.power-table-thead th.power-table-column-sort {
  background: #fff;
}
.power-table-thead > tr > th,
.power-table-tbody > tr > td,
.power-table tfoot > tr > th,
.power-table tfoot > tr > td {
  padding: 8px 16px;
}
/* Table Custom End */


/* Color Change Start */

::selection {
  background: #005594;
}
html {
  --antd-wave-shadow-color: #005594;
}
[power-click-animating-without-extra-node='true']::after,
.power-click-animating-node {
  box-shadow: 0 0 0 0 #005594;
}
@keyframes waveEffect {
  100% {
    box-shadow: 0 0 0 #005594;
  }
}
.power-alert-info .power-alert-icon {
  color: #005594;
}
.power-anchor-ink-ball {
  border: 2px solid #005594;
}
.power-anchor-link-active > .power-anchor-link-title {
  color: #005594;
}

.power-btn-primary {
  border-color: #005594;
  background: #005594;
}

.power-btn-background-ghost.power-btn-primary {
  color: #005594;
  border-color: #005594;
}
.power-picker-calendar-full .power-picker-panel .power-picker-cell-selected .power-picker-calendar-date .power-picker-calendar-date-value,
.power-picker-calendar-full .power-picker-panel .power-picker-cell-selected:hover .power-picker-calendar-date .power-picker-calendar-date-value,
.power-picker-calendar-full .power-picker-panel .power-picker-cell-selected .power-picker-calendar-date-today .power-picker-calendar-date-value,
.power-picker-calendar-full .power-picker-panel .power-picker-cell-selected:hover .power-picker-calendar-date-today .power-picker-calendar-date-value {
  color: #005594;
}
.power-picker-calendar-full .power-picker-panel .power-picker-calendar-date-today {
  border-color: #005594;
}
.power-card-actions > li > span:hover {
  color: #005594;
}
.power-card-actions > li > span a:not(.power-btn):hover,
.power-card-actions > li > span > .anticon:hover {
  color: #005594;
}
.power-cascader-checkbox-wrapper:hover .power-cascader-checkbox-inner,
.power-cascader-checkbox:hover .power-cascader-checkbox-inner,
.power-cascader-checkbox-input:focus + .power-cascader-checkbox-inner {
  border-color: #005594;
}
.power-cascader-checkbox-checked::after {
  border: 1px solid #005594;
}
.power-cascader-checkbox-checked .power-cascader-checkbox-inner {
  background-color: #005594;
  border-color: #005594;
}
.power-cascader-checkbox-indeterminate .power-cascader-checkbox-inner::after {
  background-color: #005594;
}
.power-checkbox-wrapper:hover .power-checkbox-inner,
.power-checkbox:hover .power-checkbox-inner,
.power-checkbox-input:focus + .power-checkbox-inner {
  border-color: #005594;
}
.power-checkbox-checked::after {
  border: 1px solid #005594;
}
.power-checkbox-checked .power-checkbox-inner {
  background-color: #005594;
  border-color: #005594;
}
.power-checkbox-indeterminate .power-checkbox-inner::after {
  background-color: #005594;
}
.power-picker-range .power-picker-active-bar {
  background: #005594;
}
.power-picker-panel-focused {
  border-color: #005594;
}
.power-picker-header-view button:hover {
  color: #005594;
}
.power-picker-cell-in-view.power-picker-cell-today .power-picker-cell-inner::before {
  border: 1px solid #005594;
}

.power-menu-dark .power-menu-item:hover,
.power-menu-dark .power-menu-item-active,
.power-menu-dark .power-menu-submenu-active,
.power-menu-dark .power-menu-submenu-open,
.power-menu-dark .power-menu-submenu-selected,
.power-menu-dark .power-menu-submenu-title:hover {
  color: #005594;
}
.power-picker-cell-in-view.power-picker-cell-selected .power-picker-cell-inner,
.power-picker-cell-in-view.power-picker-cell-range-start .power-picker-cell-inner,
.power-picker-cell-in-view.power-picker-cell-range-end .power-picker-cell-inner {
  background: #005594;
}
.power-picker-week-panel-row-selected td,
.power-picker-week-panel-row-selected:hover td {
  background: #005594;
}
.power-dropdown-menu-item-selected,
.power-dropdown-menu-submenu-title-selected {
  color: #005594;
}
.power-dropdown-menu-submenu-selected .power-dropdown-menu-submenu-title {
  color: #005594;
}
.power-dropdown-menu-dark .power-dropdown-menu-item-selected,
.power-dropdown-menu-dark .power-dropdown-menu-item-selected:hover,
.power-dropdown-menu-dark .power-dropdown-menu-item-selected > a {
  background: #005594;
}
.power-form-item-feedback-icon-validating {
  color: #005594;
}
.power-input-group-addon .power-select-open .power-select-selector,
.power-input-group-addon .power-select-focused .power-select-selector {
  color: #005594;
}
.power-input-number-group-addon .power-select-open .power-select-selector,
.power-input-number-group-addon .power-select-focused .power-select-selector {
  color: #005594;
}
.power-list-item-meta-title > a:hover {
  color: #005594;
}
.power-menu-dark.power-menu-dark:not(.power-menu-horizontal) .power-menu-item-danger.power-menu-item-selected {
  color: #005594;
}
.power-menu-submenu-selected {
  color: #005594;
}
.power-menu-item-selected {
  color: #005594;
}
.power-menu-item a:hover {
  color: #005594;
}
.power-menu-item-selected {
  color: #005594;
}
.power-menu-item-selected a,
.power-menu-item-selected a:hover {
  color: #005594;
}
.power-menu-submenu:hover > .power-menu-submenu-title > .power-menu-submenu-expand-icon,
.power-menu-submenu:hover > .power-menu-submenu-title > .power-menu-submenu-arrow {
  color: #005594;
}
.power-menu-vertical .power-menu-submenu-selected,
.power-menu-vertical-left .power-menu-submenu-selected,
.power-menu-vertical-right .power-menu-submenu-selected {
  color: #005594;
}
.power-menu-horizontal:not(.power-menu-dark) > .power-menu-item:hover,
.power-menu-horizontal:not(.power-menu-dark) > .power-menu-submenu:hover,
.power-menu-horizontal:not(.power-menu-dark) > .power-menu-item-active,
.power-menu-horizontal:not(.power-menu-dark) > .power-menu-submenu-active,
.power-menu-horizontal:not(.power-menu-dark) > .power-menu-item-open,
.power-menu-horizontal:not(.power-menu-dark) > .power-menu-submenu-open,
.power-menu-horizontal:not(.power-menu-dark) > .power-menu-item-selected,
.power-menu-horizontal:not(.power-menu-dark) > .power-menu-submenu-selected {
  color: #005594;
}
.power-menu-horizontal:not(.power-menu-dark) > .power-menu-item:hover::after,
.power-menu-horizontal:not(.power-menu-dark) > .power-menu-submenu:hover::after,
.power-menu-horizontal:not(.power-menu-dark) > .power-menu-item-active::after,
.power-menu-horizontal:not(.power-menu-dark) > .power-menu-submenu-active::after,
.power-menu-horizontal:not(.power-menu-dark) > .power-menu-item-open::after,
.power-menu-horizontal:not(.power-menu-dark) > .power-menu-submenu-open::after,
.power-menu-horizontal:not(.power-menu-dark) > .power-menu-item-selected::after,
.power-menu-horizontal:not(.power-menu-dark) > .power-menu-submenu-selected::after {
  border-bottom: 2px solid #005594;
}
.power-menu-horizontal > .power-menu-item a:hover {
  color: #005594;
}
.power-menu-horizontal > .power-menu-item-selected a {
  color: #005594;
}
.power-menu-vertical .power-menu-item::after,
.power-menu-vertical-left .power-menu-item::after,
.power-menu-vertical-right .power-menu-item::after,
.power-menu-inline .power-menu-item::after {
  border-right: 3px solid #005594;
}
.power-menu-light .power-menu-item:hover,
.power-menu-light .power-menu-item-active,
.power-menu-light .power-menu:not(.power-menu-inline) .power-menu-submenu-open,
.power-menu-light .power-menu-submenu-active,
.power-menu-light .power-menu-submenu-title:hover {
  color: #005594;
}
.power-menu-dark .power-menu-item:hover,
.power-menu-dark .power-menu-item-active,
.power-menu-dark .power-menu-submenu-active,
.power-menu-dark .power-menu-submenu-open,
.power-menu-dark .power-menu-submenu-selected,
.power-menu-dark .power-menu-submenu-title:hover {
  color: #005594;
}
.power-menu-dark .power-menu-item:hover > a,
.power-menu-dark .power-menu-item-active > a,
.power-menu-dark .power-menu-submenu-active > a,
.power-menu-dark .power-menu-submenu-open > a,
.power-menu-dark .power-menu-submenu-selected > a,
.power-menu-dark .power-menu-submenu-title:hover > a,
.power-menu-dark .power-menu-item:hover > span > a,
.power-menu-dark .power-menu-item-active > span > a,
.power-menu-dark .power-menu-submenu-active > span > a,
.power-menu-dark .power-menu-submenu-open > span > a,
.power-menu-dark .power-menu-submenu-selected > span > a,
.power-menu-dark .power-menu-submenu-title:hover > span > a {
  color: #005594;
}
.power-menu-dark .power-menu-item:hover > .power-menu-submenu-title > .power-menu-submenu-arrow::after,
.power-menu-dark .power-menu-item-active > .power-menu-submenu-title > .power-menu-submenu-arrow::after,
.power-menu-dark .power-menu-submenu-active > .power-menu-submenu-title > .power-menu-submenu-arrow::after,
.power-menu-dark .power-menu-submenu-open > .power-menu-submenu-title > .power-menu-submenu-arrow::after,
.power-menu-dark .power-menu-submenu-selected > .power-menu-submenu-title > .power-menu-submenu-arrow::after,
.power-menu-dark .power-menu-submenu-title:hover > .power-menu-submenu-title > .power-menu-submenu-arrow::after,
.power-menu-dark .power-menu-item:hover > .power-menu-submenu-title > .power-menu-submenu-arrow::before,
.power-menu-dark .power-menu-item-active > .power-menu-submenu-title > .power-menu-submenu-arrow::before,
.power-menu-dark .power-menu-submenu-active > .power-menu-submenu-title > .power-menu-submenu-arrow::before,
.power-menu-dark .power-menu-submenu-open > .power-menu-submenu-title > .power-menu-submenu-arrow::before,
.power-menu-dark .power-menu-submenu-selected > .power-menu-submenu-title > .power-menu-submenu-arrow::before,
.power-menu-dark .power-menu-submenu-title:hover > .power-menu-submenu-title > .power-menu-submenu-arrow::before {
  background: #005594;
}
.power-menu-dark .power-menu-item-selected {
  color: #005594;
}
.power-menu-dark .power-menu-item-selected > a,
.power-menu-dark .power-menu-item-selected > span > a,
.power-menu-dark .power-menu-item-selected > a:hover,
.power-menu-dark .power-menu-item-selected > span > a:hover {
  color: #005594;
}
.power-menu-dark .power-menu-item-selected .power-menu-item-icon,
.power-menu-dark .power-menu-item-selected .anticon {
  color: #005594;
}
.power-message-info .anticon,
.power-message-loading .anticon {
  color: #005594;
}
.power-modal-confirm-info .power-modal-confirm-body > .anticon {
  color: #005594;
}
.anticon.power-notification-notice-icon-info {
  color: #005594;
}
.power-pagination-item:hover {
  border-color: #005594;
  transition: all 0.3s;
}
.power-pagination-item:hover a {
  color: #005594;
}
.power-pagination-item:focus-visible {
  border-color: #005594;
}
.power-pagination-item:focus-visible a {
  color: #005594;
}
.power-pagination-item-active {
  border-color: #005594;
}
.power-pagination-item-active a {
  color: #005594;
}
.power-pagination-jump-prev .power-pagination-item-container .power-pagination-item-link-icon,
.power-pagination-jump-next .power-pagination-item-container .power-pagination-item-link-icon {
  color: #005594;
}
.power-pagination-prev:focus-visible .power-pagination-item-link,
.power-pagination-next:focus-visible .power-pagination-item-link {
  color: #005594;
  border-color: #005594;
}
.power-pagination-prev:hover .power-pagination-item-link,
.power-pagination-next:hover .power-pagination-item-link {
  color: #005594;
  border-color: #005594;
}
.power-pagination-simple .power-pagination-simple-pager input:hover {
  border-color: #005594;
}
.power-popover-blue .power-popover-inner {
  background-color: #005594;
}
.power-popover-blue .power-popover-arrow-content {
  background-color: #005594;
}
.power-progress-steps-item-active {
  background: #005594;
}
.power-progress-inner:not(.power-progress-circle-gradient) .power-progress-circle-path {
  stroke: #005594;
}
.power-progress-success-bg,
.power-progress-bg {
  background-color: #005594;
}
.power-radio-wrapper:hover .power-radio,
.power-radio:hover .power-radio-inner,
.power-radio-input:focus + .power-radio-inner {
  border-color: #005594;
}
.power-radio-checked::after {
  border: 1px solid #005594;
}
.power-radio-inner::after {
  background-color: #005594;
}
.power-radio-checked .power-radio-inner {
  border-color: #005594;
}
.power-radio-button-wrapper:hover {
  color: #005594;
}
.power-radio-button-wrapper-checked:not(.power-radio-button-wrapper-disabled) {
  color: #005594;
  border-color: #005594;
}
.power-radio-button-wrapper-checked:not(.power-radio-button-wrapper-disabled)::before {
  background-color: #005594;
}
.power-radio-button-wrapper-checked:not(.power-radio-button-wrapper-disabled):first-child {
  border-color: #005594;
}
.power-radio-group-solid .power-radio-button-wrapper-checked:not(.power-radio-button-wrapper-disabled) {
  background: #005594;
  border-color: #005594;
}
.power-result-info .power-result-icon > .anticon {
  color: #005594;
}
.power-select-item-option-selected:not(.power-select-item-option-disabled) .power-select-item-option-state {
  color: #005594;
}
.power-slider-handle.power-tooltip-open {
  border-color: #005594;
}
.power-spin {
  color: #005594;
}
.power-spin-dot-item {
  background-color: #005594;
}
.power-steps-item-icon .power-steps-icon {
  color: #005594;
}
.power-steps-item-process .power-steps-item-icon {
  border-color: #005594;
}
.power-steps-item-process .power-steps-item-icon > .power-steps-icon {
  color: #005594;
}
.power-steps-item-process .power-steps-item-icon > .power-steps-icon .power-steps-icon-dot {
  background: #005594;
}
.power-steps-item-process > .power-steps-item-container > .power-steps-item-icon {
  background: #005594;
}
.power-steps-item-finish .power-steps-item-icon {
  background-color: #fff;
  border-color: #005594;
}
.power-steps-item-finish .power-steps-item-icon > .power-steps-icon {
  color: #005594;
}
.power-steps-item-finish .power-steps-item-icon > .power-steps-icon .power-steps-icon-dot {
  background: #005594;
}
.power-steps-item-finish > .power-steps-item-container > .power-steps-item-content > .power-steps-item-title::after {
  background-color: #005594;
}
.power-steps-item-finish > .power-steps-item-container > .power-steps-item-tail::after {
  background-color: #005594;
}
.power-steps .power-steps-item:not(.power-steps-item-active) > .power-steps-item-container[role='button']:hover .power-steps-item-title,
.power-steps .power-steps-item:not(.power-steps-item-active) > .power-steps-item-container[role='button']:hover .power-steps-item-subtitle,
.power-steps .power-steps-item:not(.power-steps-item-active) > .power-steps-item-container[role='button']:hover .power-steps-item-description {
  color: #005594;
}
.power-steps .power-steps-item:not(.power-steps-item-active):not(.power-steps-item-process) > .power-steps-item-container[role='button']:hover .power-steps-item-icon {
  border-color: #005594;
}
.power-steps .power-steps-item:not(.power-steps-item-active):not(.power-steps-item-process) > .power-steps-item-container[role='button']:hover .power-steps-item-icon .power-steps-icon {
  color: #005594;
}
.power-steps-item-custom.power-steps-item-process .power-steps-item-icon > .power-steps-icon {
  color: #005594;
}
.power-steps-navigation .power-steps-item::before {
  background-color: #005594;
}
.power-switch-checked {
  background-color: #005594;
}
.power-switch-checked .power-switch-loading-icon {
  color: #005594;
}
.power-table-thead th.power-table-column-has-sorters:focus-visible {
  color: #005594;
}
.power-table-column-sorter-up.active,
.power-table-column-sorter-down.active {
  color: #005594;
}
.power-table-filter-trigger.active {
  color: #005594;
}
.power-tabs-card > .power-tabs-nav .power-tabs-tab-active,
.power-tabs-card > div > .power-tabs-nav .power-tabs-tab-active {
  color: #005594;
}
.power-tabs-ink-bar {
  background: #005594;
}
.power-tabs-tab.power-tabs-tab-active .power-tabs-tab-btn {
  color: #005594;
}
.power-tag-checkable:not(.power-tag-checkable-checked):hover {
  color: #005594;
}
.power-tag-checkable-checked {
  background-color: #005594;
}
.power-tag-blue-inverse {
  background: #005594;
  border-color: #005594;
}
.power-tag-processing {
  color: #005594;
}
.power-timeline-item-head-blue {
  color: #005594;
  border-color: #005594;
}
.power-tooltip-blue .power-tooltip-inner {
  background-color: #005594;
}
.power-tooltip-blue .power-tooltip-arrow-content::before {
  background: #005594;
}
.power-tree.power-tree-directory .power-tree-treenode-selected:hover::before,
.power-tree.power-tree-directory .power-tree-treenode-selected::before {
  background: #005594;
}
.power-tree-checkbox-wrapper:hover .power-tree-checkbox-inner,
.power-tree-checkbox:hover .power-tree-checkbox-inner,
.power-tree-checkbox-input:focus + .power-tree-checkbox-inner {
  border-color: #005594;
}
.power-tree-checkbox-checked::after {
  border: 1px solid #005594;
}
.power-tree-checkbox-checked .power-tree-checkbox-inner {
  background-color: #005594;
  border-color: #005594;
}
.power-tree-checkbox-indeterminate .power-tree-checkbox-inner::after {
  background-color: #005594;
}
.power-tree.power-tree-block-node .power-tree-list-holder-inner .power-tree-treenode.dragging::after {
  border: 1px solid #005594;
}
.power-tree-switcher-loading-icon {
  color: #005594;
}
.power-tree-node-content-wrapper .power-tree-drop-indicator {
  background-color: #005594;
}
.power-tree-node-content-wrapper .power-tree-drop-indicator::after {
  border: 2px solid #005594;
}
.power-tree .power-tree-treenode.drop-container > [draggable] {
  box-shadow: 0 0 0 2px #005594;
}
.power-select-tree-checkbox-wrapper:hover .power-select-tree-checkbox-inner,
.power-select-tree-checkbox:hover .power-select-tree-checkbox-inner,
.power-select-tree-checkbox-input:focus + .power-select-tree-checkbox-inner {
  border-color: #005594;
}
.power-select-tree-checkbox-checked::after {
  border: 1px solid #005594;
}
.power-select-tree-checkbox-checked .power-select-tree-checkbox-inner {
  background-color: #005594;
  border-color: #005594;
}
.power-select-tree-checkbox-indeterminate .power-select-tree-checkbox-inner::after {
  background-color: #005594;
}
.power-select-tree.power-select-tree-block-node .power-select-tree-list-holder-inner .power-select-tree-treenode.dragging::after {
  border: 1px solid #005594;
}
.power-select-tree-switcher-loading-icon {
  color: #005594;
}
.power-select-tree-node-content-wrapper .power-tree-drop-indicator {
  background-color: #005594;
}
.power-select-tree-node-content-wrapper .power-tree-drop-indicator::after {
  border: 2px solid #005594;
}
.power-select-tree .power-select-tree-treenode.drop-container > [draggable] {
  box-shadow: 0 0 0 2px #005594;
}
.power-upload.power-upload-select-picture-card:hover {
  border-color: #005594;
}
.power-btn-primary:hover,
.power-btn-primary:focus {
  border-color: #002f6c;
  background: #002f6c;
}
.power-btn:hover,
.power-btn:focus {
  color: #f6ffed;
  border-color: #002f6c;
}
.power-btn-group .power-btn-primary:not(:first-child):not(:last-child) {
  border-right-color: #002f6c;
  border-left-color: #002f6c;
}
.power-btn-group .power-btn-primary:first-child:not(:last-child) {
  border-right-color: #002f6c;
}
.power-btn-group .power-btn-primary:last-child:not(:first-child),
.power-btn-group .power-btn-primary + .power-btn-primary {
  border-left-color: #002f6c;
}
.power-btn-ghost:hover,
.power-btn-ghost:focus {
  color: #002f6c;
  border-color: #002f6c;
}
.power-btn-dashed:hover,
.power-btn-dashed:focus {
  color: #002f6c;
  border-color: #002f6c;
}
.power-btn-link:hover,
.power-btn-link:focus {
  color: #002f6c;
  border-color: #002f6c;
}
.power-btn-default:hover,
.power-btn-default:focus {
  color: #002f6c;
  border-color: #002f6c;
}
.power-btn-text:hover,
.power-btn-text:focus {
  color: #002f6c;
  border-color: #002f6c;
}
.power-btn-dangerous.power-btn-link:hover,
.power-btn-dangerous.power-btn-link:focus {
  color: #002f6c;
  border-color: #002f6c;
}
.power-btn-dangerous.power-btn-text:hover,
.power-btn-dangerous.power-btn-text:focus {
  color: #002f6c;
  border-color: #002f6c;
}
.power-btn.power-btn-background-ghost:hover,
.power-btn.power-btn-background-ghost:focus {
  color: #002f6c;
  border-color: #002f6c;
}
.power-btn-background-ghost.power-btn-primary:hover,
.power-btn-background-ghost.power-btn-primary:focus {
  color: #002f6c;
  border-color: #002f6c;
}
.power-btn-group-rtl.power-btn-group .power-btn-primary:last-child:not(:first-child),
.power-btn-group-rtl.power-btn-group .power-btn-primary + .power-btn-primary {
  border-right-color: #002f6c;
}
.power-btn-group-rtl.power-btn-group .power-btn-primary:last-child:not(:first-child)[disabled],
.power-btn-group-rtl.power-btn-group .power-btn-primary + .power-btn-primary[disabled] {
  border-left-color: #002f6c;
}
.power-select:not(.power-select-disabled):hover .power-select-selector {
  border-color: #002f6c;
}
.power-picker:hover,
.power-picker-focused {
  border-color: #002f6c;
  box-shadow: 0 0 0 2px rgba(37, 150, 190, 0.2);
}
.power-picker-focused {
  border-color: #002f6c;
}
.power-picker-input > input:hover {
  border-color: #002f6c;
}
.power-picker-input > input:focus,
.power-picker-input > input-focused {
  border-color: #002f6c;
}
.power-input-affix-wrapper:hover {
  border-color: #002f6c;
}
.power-input-affix-wrapper:focus,
.power-input-affix-wrapper-focused {
  border-color: #002f6c;
  box-shadow: 0 0 0 2px rgba(37, 150, 190, 0.2);
}
.power-input-affix-wrapper:not(.power-input-affix-wrapper-disabled):hover {
  border-color: #002f6c;
}
.power-input:hover {
  border-color: #002f6c;
}
.power-input:focus,
.power-input-focused {
  border-color: #002f6c;
  box-shadow: 0 0 0 2px rgba(37, 150, 190, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.power-input-search .power-input:hover,
.power-input-search .power-input:focus {
  border-color: #002f6c;
}
.power-input-search .power-input:hover + .power-input-group-addon .power-input-search-button:not(.power-btn-primary),
.power-input-search .power-input:focus + .power-input-group-addon .power-input-search-button:not(.power-btn-primary) {
  border-left-color: #002f6c;
}
.power-input-search-rtl .power-input:hover + .power-input-group-addon .power-input-search-button:not(.power-btn-primary):hover,
.power-input-search-rtl .power-input:focus + .power-input-group-addon .power-input-search-button:not(.power-btn-primary):hover {
  border-left-color: #002f6c;
}
.power-input-search-rtl > .power-input-group > .power-input-affix-wrapper:hover,
.power-input-search-rtl > .power-input-group > .power-input-affix-wrapper-focused {
  border-right-color: #002f6c;
}
.power-input-number-affix-wrapper:hover {
  border-color: #002f6c;
}
.power-input-number-affix-wrapper:focus,
.power-input-number-affix-wrapper-focused {
  border-color: #002f6c;
  box-shadow: 0 0 0 2px rgba(37, 150, 190, 0.2);
}
.power-input-number-affix-wrapper:not(.power-input-number-affix-wrapper-disabled):hover {
  border-color: #002f6c;
}
.power-input-number:hover {
  border-color: #002f6c;
}
.power-input-number:focus,
.power-input-number-focused {
  border-color: #002f6c;
  box-shadow: 0 0 0 2px rgba(37, 150, 190, 0.2);
}
.power-input-number-handler:hover .power-input-number-handler-up-inner,
.power-input-number-handler:hover .power-input-number-handler-down-inner {
  color: #002f6c;
}
.power-input-number:hover {
  border-color: #002f6c;
}
.power-input-number-focused {
  border-color: #002f6c;
  box-shadow: 0 0 0 2px rgba(37, 150, 190, 0.2);
}
.power-mentions:hover {
  border-color: #002f6c;
}
.power-mentions:focus,
.power-mentions-focused {
  border-color: #002f6c;
  box-shadow: 0 0 0 2px rgba(37, 150, 190, 0.2);
}
.power-mentions-focused {
  border-color: #002f6c;
  box-shadow: 0 0 0 2px rgba(37, 150, 190, 0.2);
}
.power-pagination-item-active:hover {
  border-color: #002f6c;
}
.power-pagination-item-active:focus-visible {
  border-color: #002f6c;
}
.power-pagination-item-active:hover a {
  color: #002f6c;
}
.power-pagination-item-active:focus-visible a {
  color: #002f6c;
}
.power-pagination-prev:hover button,
.power-pagination-next:hover button {
  border-color: #002f6c;
}
.power-pagination-options-quick-jumper input:hover {
  border-color: #002f6c;
}
.power-pagination-options-quick-jumper input:focus,
.power-pagination-options-quick-jumper input-focused {
  border-color: #002f6c;
  box-shadow: 0 0 0 2px rgba(37, 150, 190, 0.2);
}
.power-pagination-simple .power-pagination-simple-pager input:focus {
  border-color: #002f6c;
}
.power-radio-button-wrapper-checked:not(.power-radio-button-wrapper-disabled):hover {
  color: #002f6c;
  border-color: #002f6c;
}
.power-radio-button-wrapper-checked:not(.power-radio-button-wrapper-disabled):hover::before {
  background-color: #002f6c;
}
.power-radio-group-solid .power-radio-button-wrapper-checked:not(.power-radio-button-wrapper-disabled):hover {
  background: #002f6c;
  border-color: #002f6c;
}
.power-radio-button-wrapper-checked:not([class*=' power-radio-button-wrapper-disabled']).power-radio-button-wrapper:first-child {
  border-right-color: #002f6c;
}
.power-select-focused:not(.power-select-disabled).power-select:not(.power-select-customize-input) .power-select-selector {
  border-color: #002f6c;
  box-shadow: 0 0 0 2px rgba(37, 150, 190, 0.2);
}
.power-select:not(.power-select-disabled):hover .power-select-selector {
  border-color: #002f6c;
}
.power-tabs-dropdown-menu-item-remove:hover {
  color: #002f6c;
}
.power-tabs > .power-tabs-nav .power-tabs-nav-add:hover,
.power-tabs > div > .power-tabs-nav .power-tabs-nav-add:hover {
  color: #002f6c;
}
.power-tabs-tab:hover {
  color: #002f6c;
}
.power-transfer-status-error .power-transfer-list-search:not([disabled]):hover {
  border-color: #002f6c;
}
.power-transfer-status-error .power-transfer-list-search:not([disabled]):focus {
  border-color: #002f6c;
  box-shadow: 0 0 0 2px rgba(37, 150, 190, 0.2);
}
.power-transfer-status-warning .power-transfer-list-search:not([disabled]):hover {
  border-color: #002f6c;
}
.power-transfer-status-warning .power-transfer-list-search:not([disabled]):focus {
  border-color: #002f6c;
  box-shadow: 0 0 0 2px rgba(37, 150, 190, 0.2);
}
.power-upload.power-upload-drag:not(.power-upload-disabled):hover {
  border-color: #002f6c;
}
.power-upload.power-upload-drag p.power-upload-drag-icon .anticon {
  color: #002f6c;
}
/* Color Change End */

.power-input[disabled] {
  color: rgba(0, 0, 0, 0.4);
  background-color: #fff;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: default;
  opacity: 1;
}

.power-select-disabled.power-select:not(.power-select-customize-input) .power-select-selector {
  color: rgba(0, 0, 0, 0.4);
  background: #fff;
  cursor: default;
}

.power-menu-dark .power-menu-submenu-open {
  color: rgba(255, 255, 255, 0.65)
}

.power-menu-dark .power-menu-submenu-open>.power-menu-submenu-title>.power-menu-submenu-arrow::after,
.power-menu-dark .power-menu-submenu-open>.power-menu-submenu-title>.power-menu-submenu-arrow::before {
  background-color: rgba(255, 255, 255, 0.65)
}

.badge {
  @apply py-1 px-4 text-white rounded-xl;
}

.icon {
  @apply rounded-full p-2;
}

.card {
  @apply shadow-xl rounded-3xl bg-white;
}

.menu-button {
  @apply bg-white cursor-pointer font-bold transition-all;
}

.tabs-button {
  @apply bg-white text-primary cursor-pointer font-semibold transition-all;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.infex-input-number-handler-wrap {
  display: none;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.power-menu::-webkit-scrollbar {
  width: 5px;
}

.power-menu::-webkit-scrollbar-track {
  background: #555;
  border-radius: 4px;
}

.power-menu::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.power-menu::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.leaflet-control {
  display: none;
}

.power-table {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  font-size: 14px;
  background: #fff;
  border-radius: 5px;
}

.power-table table {
  width: 100%;
  text-align: left;
  border-radius: 15px 15px 0 0;
  border-collapse: separate;
  border-spacing: 0;
}

.power-table.power-table-small .power-table-title,
.power-table.power-table-small .power-table-footer,
.power-table.power-table-small .power-table-thead>tr>th,
.power-table.power-table-small .power-table-tbody>tr>td,
.power-table.power-table-small tfoot>tr>th,
.power-table.power-table-small tfoot>tr>td {
  padding: 4px 4px;
}

.power-table-container table>thead>tr:first-child th:first-child {
  border-top-left-radius: 4px;
}

.power-table-container table>thead>tr:first-child th:last-child {
  border-top-right-radius: 4px;
}

.power-input {
  border-radius: 4px;
}

.power-select:not(.power-select-customize-input) .power-select-selector {
  border-radius: 4px;
}

.power-btn {
  border-radius: 4px;
}

.table-row-green {
  background-color: #f6ffed;
}
.table-row-red {
  background-color: #fff1f0;
}

.power-table-tbody > tr.power-table-row:hover > td, .power-table-tbody > tr > td.power-table-cell-row-hover{
  background: #d3eaff;
}

.login-card {
  background: radial-gradient(
    100% 100% at 90% top,
    #004374, #031834
  );
}


#quote-container {
  border-top: 6px solid #002f6c;
  border-bottom: 1px dotted #9b9b9b;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 16px;
}
blockquote {
  font-size: 20px;
  font-weight: 700;
  position: relative;
  margin-bottom: 20px;
  margin-top: 0px;
  text-align: left;
}
blockquote::before {
  color: #2077b6;
  content: "“";
  font-size: 40px;
  left: -40px;
  top: 0;
  line-height: 1;
  font-family: serif;
  pointer-events: none;
  position: absolute;
}
#quote-footer {
  display: flex;
  gap: 15px;
  font-size: 11.2px;
  font-weight: 700;
  line-height: 16px;
  margin-left: 40px;
}
#quote-author {
  color: #005594;
}
#quote-author::before {
  content: "—";
  pointer-events: none;
  margin-right: 5px;
}
#author-dash {
  margin-right: 5px;
}
#quote-major {
  color: #747474;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.power-image-preview-img-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}

.content-img {
  width: 100%;
}